export const splitFilter = <T>(
  array: T[],
  filter: (item: T) => boolean
): [T[], T[]] => {
  const included: T[] = [];
  const excluded: T[] = [];

  for (const item of array) {
    if (filter(item)) {
      included.push(item);
    } else {
      excluded.push(item);
    }
  }

  return [included, excluded];
};
